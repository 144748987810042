<template>
  <div role="main" class="main">
    <section class="">
      <div class="registration-page-content common-page">
        <div class="container">
          <div class="loaderWrapper" v-if="showLoader">
            <double-bounce ></double-bounce>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 text-left page-heading">
              <div class="row">
                <div class="col-md-8">
                  <b-form>
                    <h3 class="m-0 mb-3">Upload Your Photos</h3>
                    <div class="custom-group mb-2">
                      <div
                        class="mr-3"
                        v-if="photo.img"
                        @click="$refs.photoFile.click()"
                      >
                        <img
                          :src="photo.img"
                          class="img-fluid select-profile-img"
                        />
                      </div>
                      <p class="m-0 text-white text-normal mb-3">
                        Please leave a personal message that will display along
                        the photo
                      </p>
                      <!-- type text -->
                      <textarea
                        type="text"
                        v-model="photo.message"
                        class="form-control form-input-control white-border"
                        placeholder="Please leave a personal message or good wishes to the happy couple here"
                      ></textarea>
                      <!-- type text -->
                    </div>
                    <!-- upload image -->
                    <div>
                      <div class="row">
                        <div class="col-12 col-md-6 pr-lg-0">
                          <div class="upload-photo-area pointer bg-white p-2 d-flex align-items-center" @click="$refs.photoFile.click()" v-if="!photo.img">
                            <h6 class="mb-0">Upload photo</h6>
                          </div>
                          <input type="file" ref="photoFile" @change="inputFile" accept="image/x-png,image/gif,image/jpeg" style="display:none">
                        </div>
                        <div class="col-md-6 col-4 pl-lg-0">
                          <button type="button" class="btn btn-primary" @click="submit" style="float: right;">Submit</button>
                        </div>
                      </div>
                    </div>
                  </b-form>
                </div>
                <div class="col-md-4"></div>
              </div>
            </div>
            <div class="col-md-6" v-show="imgList.length>0">
            <h6 class="text-primary mt-4 mt-lg-0 mb-2 text-left">Click on photos to enlarge
            </h6>
            <div class="row">
              <div class="col-md-3 col-6 text-center mb-2" v-for="image in imgList" :key="image.id">
                <div class="uploaded-photo-box pointer" @click="goNext('enlarge',image.photo_id)">
                  <img :src="image.picture_url" class="img-uploaded">
                  <!-- <h6 class="text-yellow">{{image.message}}</h6> -->
                  <h6 class="text-yellow"><br></h6>
                </div>
              </div>
            </div>
          </div>
            <!-- <div class="col-12 col-md-6 text-left">
              <div class="row">
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed1.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed1.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed2.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed2.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed3.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed3.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed4.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed4.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed5.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed5.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed6.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed6.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed7.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed7.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed8.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed8.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed9.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed9.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed10.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed10.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed11.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed11.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed12.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed12.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed13.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed13.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed14.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed14.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed15.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed15.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed16.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed16.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed17.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed17.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed18.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed18.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed19.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed19.png')"
                  />
                </div>
                <div class="col-md-3 mb-3 text-center">
                  <img
                    :src="require('@/assets/img/wed20.png')"
                    class="img-fluid select-profile-img"
                    @click="setImage('wed20.png')"
                  />
                </div>
              </div>
            </div> -->
          </div>
          <div class="row"></div>
        </div>
      </div>
      <div class="registration-page-footer">
        <div class="footer-wrapper container-fluid">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-3"></div>
            <div class="col-lg-3"></div>
            <div class="col-lg-3">
              <div class="img-avatar-banner">
                <img
                  src="../../assets/img/backtoreturn.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name" @click="goBack()">Return to Program</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl } from "../../constants/config";
import {DoubleBounce} from 'vue-loading-spinner';
export default {
  data() {
    return {
      event_id: 0,
      visitor_id: 0,
      showLoader : false,
      photo: {
        message: "",
        img: null,
        imgFile: null,
      },
      imgList: [],
      myText: "",
    };
  },
  mounted() {
    this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).event_id
      : 0;
    this.visitor_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).token
      : 0;
    this.getImgList();
  },
  methods: {
    onInput(event) {
      this.photo.message = event.data;
    },
    clearTextarea() {
      this.$refs.emoji.clear();
    },
    goNext(page, img_id = 0) {
      if (page == "enlarge") this.$router.push("/visitor/enlarge/" + img_id);
    },
    goBack() {
      let randomnumber = Math.floor(Math.random() * 10000) + 1;
      this.$router.push("/visitor/dashboard/"+this.event_id+"?x=" + randomnumber);
    },
    inputFile(event) {
      this.photo.img = event.target.files[0].name;
      this.photo.imgFile = event.target.files[0];
      //set image to the div area
      var reader = new FileReader();
      var ref = this;
      reader.onload = function (e) {
        ref.photo.img = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    submit() {
      if (this.photo.message == "") {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Warning",
          text: "Please input a message.",
          animation_type: "slide",
        });
        return;
      }
      if (this.photo.imgFile == null) {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Warning",
          text: "Please select an image.",
          animation_type: "slide",
        });
        return;
      }
      this.showLoader = true;
      //call api to upload photo
      const formData = new FormData();
      formData.append("event_id", this.event_id);
      formData.append("visitor_id", this.visitor_id);
      formData.append("message", this.photo.message);
      formData.append("image", this.photo.imgFile);
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/photo/create`, formData)
        .then((response) => {
          let res = response.data;
          console.log("[[[[[[[[",res)
          if (res.status === true) {
            this.imgList = [];
            this.photo.message = "";
            this.photo.img = "";
            this.photo.imgFile = null;
            this.getImgList();
            this.showLoader = false;
             this.$router.push("/visitor/uploadStory/" + res.photo_id+"/"+this.event_id);
          } else {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
            this.showLoader = false;
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
              group: "foo",
              type: "warn",
              title: "Warning",
              text: "Please upload image less then 1 mb",
              animation_type: "slide",
            });
          this.showLoader = false;
        });
    },
    getImgList() {
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/photo/list`, {
          visitor_id: this.visitor_id,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            console.log("ppppppp",res.data)
            this.imgList = res.data;
            for (let i = 0; i < this.imgList.length; i++) {
              this.imgList[i].picture_url =
                `${apiBaseUrl}/` + this.imgList[i].picture_url;
            }
          } else {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    DoubleBounce
  },
  computed: {},
  watch: {},
};
</script>
<style>
@font-face {
  font-family: "Fontin-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Fontin-Regular.woff") format("woff");
}
@font-face {
  font-family: "Fontin-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Fontin-Bold.woff") format("woff");
}
.registration-page-content {
  height: calc(100vh - 135px);
  padding-top: 9rem;
  padding-bottom: 4rem;
  background-image: url("../../assets/img/back-img.png") !important;
  background-size: contain;
}
.registration-page-footer {
  position: relative;
  background-image: url("../../assets/img/footer-img.png");
  background-repeat: repeat-y;
  background-position: left top;
  background-size: contain;
  padding: 15px 0;
  border-image-slice: 1;
  border-top: 5px solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(119, 40, 3, 1) 0%,
    rgba(163, 62, 11, 1) 15%,
    rgba(195, 85, 12, 1) 33%,
    rgba(102, 29, 3, 1) 59%,
    rgba(102, 21, 3, 1) 64%,
    rgba(102, 29, 3, 1) 76%,
    rgba(157, 57, 6, 1) 88%
  );
}
.registration-page-footer::before {
  content: "";
  height: 100%;
  width: 100%;
  background: #000000b0;
  position: absolute;
  top: 0;
  left: 0;
}
.page-heading h3 {
  color: #fff;
  font-family: "Fontin-Regular";
}
.arrow-icon-wrapper {
  height: 24px;
  width: 24px;
}
.avatar-img img {
  height: 150px;
  width: 150px;
  border: 3px solid #fff;
}
.white-border {
  border: 1px solid #fff !important;
  color: #fff !important;
  background: #000000a0 !important;
}
::placeholder {
  color: #fff !important;
}
.img-avatar-banner {
  display: flex;
  align-items: center;
}
.banner-avatar {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 2px solid #fff;
  position: relative;
  z-index: 2;
}
.banner-name {
  color: #fff;
  font-weight: 400;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px 30px;
  position: relative;
  margin-left: -23px;
  z-index: 1;
  cursor: pointer;
}
.banner-name span {
  font-weight: 900;
}
.footer-wrapper {
  /* padding: 0 12vw; */
}
.c-column-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.text-normal {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 1.3;
}
.select-profile-img {
  height: 125px;
  width: 115px;
  min-height: 125px;
  min-width: 115px;
  object-fit: cover;
  border: 3px solid #fff;
  border-bottom: 10px solid #fff;
}
.loaderWrapper{
  position: absolute;
  height: 89vh;
  width: 100vw;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000082;
  z-index: 999;
}
</style>
